import request from './request'

//司机个人车辆列表
export const car_driver_car_index_request = p => {
    return request({
        method:'GET',
        url:'car_driver_car/index',
        params: p
    })
}

//司机个人车辆序列
export const car_driver_car_list_request = () => {
    return request({
        method:'GET',
        url:'car_driver_car/list'
    })
}

//添加司机个人车辆
export const car_driver_car_add_request = d => {
    return request({
        method:'POST',
        url:'car_driver_car/add',
        data: d
    })
}

//删除司机个人车辆
export const car_driver_car_del_request = id => {
    return request({
        method:'DELETE',
        url:'car_driver_car/del',
        data: {
            id: id
        }
    })
}

//修改司机个人车辆
export const car_driver_car_edit_request = d => {
    return request({
        method:'PUT',
        url:'car_driver_car/edit',
        data: d
    })
}

//获取司机个人车辆详情
export const car_driver_car_details_request = id => {
    return request({
        method:'GET',
        url:'car_driver_car/details',
        params: {
            id
        }
    })
}

//获取车辆类型序列
export const car_size_type_list_request = () => {
    return request({
        method:'GET',
        url:'car_size_type/list'
    })
}