import request from './request'

//司机个人登记列表
export const car_driver_appoint_index_request = p => {
    return request({
        method:'GET',
        url:'car_driver_appoint/index',
        params: p
    })
}

//司机个人登记详情
export const car_driver_appoint_details_request = id => {
    return request({
        method:'GET',
        url:'car_driver_appoint/details',
        params: {
            id
        }
    })
}

//司机个人在线登记
export const car_driver_appoint_add_request = d => {
    return request({
        method:'POST',
        url:'car_driver_appoint/add',
        data: d
    })
}

//司机个人取消登记
export const car_driver_appoint_cancel_request = d => {
    return request({
        method:'PUT',
        url:'car_driver_appoint/cancel',
        data: d
    })
}

//登记类型序列
export const car_business_type_list_request = () => {
    return request({
        method:'GET',
        url:'car_business_type/list'
    })
}
