<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" title_name="在线登记"></nav-bar-component>
        <van-cell-group inset >
            <van-form>
                <select-component
                    name="type"
                    lable="业务类型"
                    :farr="all_car_business_type"
                    :fvalue_name="form.car_business_type_id"
                    @clk="confirm_car_business_type_id"
                ></select-component>
                <van-radio-group v-model="form.with_car_driver_car_id">
                    <van-cell-group>
                        <van-cell clickable @click="radio = item.id" v-for="item in all_car_driver_car" :key="item.id">
                            <template #title>
                                <span>{{item.car_number}}</span>
                            </template>
                            <template #right-icon>
                                <van-radio :name="item.id" />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
                <van-field
                    v-model="form.box_num"
                    name="box_num"
                    label="集装箱号"
                    placeholder="选填"
                />
            </van-form>
            <div>
                <div class="sub_button">
                    <van-button round block type="primary" @click="add_form">登 记</van-button>
                </div>
                <div class="sub_button">
                    <van-button round block type="default" @click="$router.go(-1)">返 回</van-button>
                </div>
            </div>
        </van-cell-group>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'

import { car_driver_appoint_add_request,car_business_type_list_request } from '@/network/CarDriverAppoint'
import { car_driver_car_list_request } from '@/network/CarDriverCar'

export default {
    name:'CarDriverAppointAdd',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            form: {
                car_business_type_id: '',
                box_num: '',
                with_car_driver_car_id: ''
            },
            all_car_business_type: [],
            all_car_driver_car: []
        }
    },
    computed:{},
    methods:{
        add_form() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                car_business_type_id: this.form.car_business_type_id,
                box_num: this.form.box_num,
                with_car_driver_car_id: this.form.with_car_driver_car_id
            }
            car_driver_appoint_add_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('登记成功!')
                        this.$router.replace({
                            name: 'CarDriverAppointDetails',
                            query: {
                                id: s.result.id
                            }
                        })
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_car_business_type_id(e) {
            this.form.car_business_type_id = e.id
        },
        get_all_car_business_type() {
            this.$store.commit('true_loading')
            car_business_type_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_car_business_type = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_car_driver_car() {
            this.$store.commit('true_loading')
            car_driver_car_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_car_driver_car = s.result
                        if(s.result.length === 0) {
                            this.$router.go(-1)
                            this.$toast.fail('您名下无任何车俩信息，请先在【车辆管理】里面录入车辆信息')
                        }
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_all_car_business_type()
        this.get_all_car_driver_car()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>